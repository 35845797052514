import Rand from 'rand-seed';


// export function callInformationTextGroup() {
//     let rand = new Rand('callInformationTextGroup' + localStorage.getItem('mobile'));
//     if (rand.next() > 0.5) {
//         return 'original'
//     } else {
//         return 'price_info_added'
//     }
// }

// export function callInformationPriceDiscount() {
//     let rand = new Rand('callInformationPriceDiscount' + localStorage.getItem('mobile'));
//     if (rand.next() > 0.5) {
//         return 'original'
//     } else {
//         return 'discounted'
//     }
// }

export function meetTherapistVariants() {
    let rand = new Rand('meetTherapistVariants' + localStorage.getItem('mobile'));
    let variants = [ 'A', 'B'];
    let selectedvariant = variants[Math.floor(rand.next()*variants.length)]
    return selectedvariant
}

export const getDepressionText = (score: number) => {
    return DepressionLevels.find((e) => e.scoreRange[0] <= score && e.scoreRange[1] >= score)
}

export const getAnietyText = (score: number) => {
    return AnxietyLevels.find((e) => e.scoreRange[0] <= score && e.scoreRange[1] >= score)
}

export const DepressionLevels = [
    {
        scoreRange: [0, 4],
        level: 'Minimal symptoms of depression',
        text: `Good news: Depression symptoms may pop up now and then, but they typically do not linger.

More good news: You're in the best state of mind to build skills for lifelong mental well-being.

It's easier to learn and establish habits when you're not seriously struggling. It's like going to the gym regularly, but for your mind.`,
    },
    {
        scoreRange: [5, 9],
        level: 'Mild symptoms of depression',
        text: `Good news: Depression symptoms only occasionally interfere with your efforts to navigate life.

More good news: You're in the best state of mind to build skills for lifelong mental well-being.

It's easier to learn and establish habits when you're not seriously struggling. It's like going to the gym regularly, but for your mind.`,
    },
    {
        scoreRange: [10, 14],
        level: 'Moderate symptoms of depression',
        text: `Depression symptoms may be limiting your ability to live a satisfying, balanced life.

But there is good news: This is the time to build skills for lifelong mental well-being.

It's easier to learn and establish habits when you're not seriously struggling. It's like going to the gym regularly, but for your mind.`,
    },
    {
        scoreRange: [15, 19],
        level: 'Moderately severe symptoms of depression',
        text: `Depression symptoms may be impairing your ability to manage critical areas of life.

But there is good news: The skills you learn with Mitsu.care will enable you to manage your current overwhelming feelings, rebuild your emotional wellbeing, and ultimately establish a foundation for lifelong mental health.  

And a Mitsu therapist will be there to support you every step of the way. You don't have to do it alone.`,
    },
    {
        scoreRange: [20, Infinity],
        level: 'Severe symptoms of depression',
        text: `Depression symptoms may be making critical, everyday functions difficult and life unsatisfying for you.

But there is good news: The skills you learn with Mitsu.care will enable you to manage your current overwhelming feelings, rebuild your emotional wellbeing, and ultimately establish a foundation for lifelong mental health.  

And a Mitsu therapist will be there to support you every step of the way. You don't have to do it alone.`,
    },
];export const AnxietyLevels = [
    {
        scoreRange: [0, 4],
        level: 'Minimal symptoms of anxiety',
        text: `Good news: Anxiety symptoms only occasionally add to your stress. So you have plenty of mind space to develop skills for maintaining mental wellbeing.

It's easier to establish mental wellness habits when you're not seriously struggling.

It's a bit like going to the gym even when you're in good shape; maintaining mental wellness builds resilience that can help you through future struggles.`,
    },
    {
        scoreRange: [5, 9],
        level: 'Mild symptoms of anxiety',
        text: `Anxiety symptoms may be disrupting your peace of mind regularly. But you still have mind space to develop skills for maintaining mental wellbeing.

It's easier to establish mental wellness habits when you're not seriously struggling.

It's a bit like going to the gym to maintain good health; maintaining mental wellness builds resilience that can help you through future struggles.
        `,
    },
    {
        scoreRange: [10, 14],
        level: 'Moderate symptoms of anxiety',
        text: `Anxiety symptoms may be compromising your effectiveness frequently in key areas of life.

But with Mitsu, you can learn techniques that curb anxiety's effects on your life, help you feel more in control, and support your mental resilience in the long term.

And a Mitsu therapist will be there to support you at every step. You don't have to do it alone.`,
    },
    {
        scoreRange: [15, Infinity],
        level: 'Severe symptoms of anxiety',
        text: `Anxiety symptoms are making daily life a challenge for you.

But with Mitsu, you can learn techniques that curb anxiety's effects on your life, help you feel more in control, and support your mental resilience in the long term.

And a Mitsu therapist will be there to support you at every step. You don't have to do it alone.`,
    },
];