/* eslint-disable import/named */
import { Box, IconButton, useMediaQuery, useTheme } from '@mui/material';
import { DatePicker, LocalizationProvider, StaticDatePicker } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import moment, { Moment } from 'moment';
import { useContext, useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Navigate, useNavigate } from 'react-router-dom';
import CloseIcon from '../../assets/icons/CloseIcon';
import { AdaptiveTypography, Modal, Select, Typography } from '../../components/base';
import Button from '../../components/base/button';
import { TextField } from '../../components/common';
import {
  CALL_MAP,
  MixPanelEvents,
  educationOptionsList,
  genderOptionsList,
  occupationOptionsList,
  relationshipOptionsList,
} from '../../helpers/constant';
import { AppContext } from '../../helpers/hooks/AppContext';
import notify from '../../helpers/toastify-helper';
import { CLIENT_ROUTES } from '../../router/routes';
import { getUserDetails, updateUserBasicDetails } from '../../services/selfcheckin.service';
import style from './checkinQuestions.module.scss';
import { scheduleTimeSlot } from '../../services/callSchedule.service';

const CheckInBasicDetailsPage = () => {
  const { mixpanel } = useContext(AppContext);

  const theme = useTheme();
  const isTwoColumn = useMediaQuery(theme.breakpoints.up('sm'));
  const genderOptions = genderOptionsList;
  const educationOptions = educationOptionsList;
  const relationshipOptions = relationshipOptionsList;
  const occupationOptions = occupationOptionsList;

  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false);

    if (moment().diff(dob, 'years') < 18) {
      setDob(null);
      notify({
        message: "We're sorry. Mitsu is designed only for people who are 18 years and older.",
        severity: 'error',
        dismissible: true,
      });
    }
  };

  const [name, setName] = useState<any>(null);
  const [city, setCity] = useState<any>(null);
  const [dob, setDob] = useState<any>(null);
  const [gender, setGender] = useState<any>(null);
  // const [education, setEducation] = useState<any>(null);
  // const [relationship, setRelationShip] = useState<any>(null);
  const [occupation, setOccupation] = useState<any>(null);
  const [isOccupationHidden, setOccupationHidden] = useState<boolean>(false);

  const [isLoading, setLoading] = useState(false);

  let dobValue = null;
  let dobLabel = null;

  if (dob != null) {
    dobValue = dob;
    dobLabel = (dob as Moment).format('DD/MM/YYYY');
  }

  const formMethods = useForm();
  const navigate = useNavigate();

  const [isNameEditable, setNameEditable] = useState(true);
  const [isCityEditable, setCityEditable] = useState(true);
  // const [isEducationEditable, setEducationEditable] = useState(true);
  const [isGenderEditable, setGenderditable] = useState(true);
  // const [isRelationshipEditable, setRelationshipEditable] = useState(true);
  const [isOcuupationEditable, setOccupationEditable] = useState(true);
  const [isDOBEditable, setDOBEditable] = useState(true);

  const onSubmit = async () => {
    if (!isFormValid) {
      return;
    }
    if (moment().diff(dob, 'years') < 18) {
      notify({
        message: "We're sorry. Mitsu is designed only for people who are 18 years and older.",
        severity: 'error',
        dismissible: true,
      });
      mixpanel.people.set('eligibility_age', false)
      return;
    }
    try {
      setLoading(true);
      const result = await updateUserBasicDetails({
        address: city,
        dob: (dob as Moment).format('YYYY-MM-DD'),
        gender: gender.value,
        // education: education.value,
        occupation: occupation?.value,
        // relationshipStatus: relationship.value,
      });

      mixpanel.track(MixPanelEvents.fillProfile, {
        'Profile Fill': 'Success',
        isWebFlow: true,
      });
      navigate(`../${CLIENT_ROUTES.scheduled_call}`);


    } finally {
      setLoading(false);
    }
  };

  const isFormValid = name && city && gender && (isOccupationHidden || occupation);

  useEffect(() => {
    try {

      mixpanel.track(MixPanelEvents.profilePageView, {
        isWebFlow: true,
      });
    } catch (error) {

    }
    const getInitialUserDetails = async () => {
      try {
        const userDetails = await getUserDetails();
        if (userDetails.isCorporateActive && userDetails.isUserActive) {
          setOccupationHidden(true)
        }
        if (userDetails.name) {
          setName(userDetails.name);
          setNameEditable(false);
        }
        if (userDetails.address) {
          setCity(userDetails.address);
          setCityEditable(false);
        }
        if (userDetails.dateOfBirth) {
          const initDob = moment(userDetails.dateOfBirth, 'YYYY-MM-DD');
          setDob(initDob);
          setDOBEditable(false);
        }
        if (userDetails.gender) {
          const val = genderOptions.find((option) => option.value == userDetails.gender);
          setGender(val);
          setGenderditable(false);
        }
        if (userDetails.occupation) {
          const val = occupationOptions.find((option) => option.value == userDetails.occupation);
          setOccupation(val);
          setOccupationEditable(false);
        }
      } finally {
        setLoading(false);
      }
    };

    getInitialUserDetails();
  }, []);
  const datePickerRef = useRef(null);

  const [_, forceRefresh] = useState(Math.random())
  return (
    <div className={style.questionsPageContainer}>
      <div className={style.questionsPageCenter}>
        <div className={style.questionsPage}>
          <form onSubmit={formMethods.handleSubmit(onSubmit)}>
            <div className={`${style.questionsHeadContainer} ${isTwoColumn ? '' : style.alignJustify}`}>
              <Typography variant="h1">Introduce yourself</Typography>
              <AdaptiveTypography variant="subheading1">
                Sharing some basic details about your life will help your Mitsu therapist better understand your context and prepare for your call.
              </AdaptiveTypography>
              <div className={style.basicDetailsContainer}>
                <TextField
                  inputClassName={style.inputLong}
                  name="city"
                  disabled={!isCityEditable}
                  value={city ?? ''}
                  onChange={(event) => setCity((event.target.value.match(/[a-z A-Z]/g) || []).join(''))}
                  label="Enter city name"
                  placeholder=""
                  required
                />
                <div style={{ height: '32px' }} />
                <div className={style.fieldRow}>

                  <LocalizationProvider dateAdapter={AdapterMoment}  >
                    <label className={`text-field-root`}>
                      {'Date of Birth' && (
                        <span data-value={'*'} className={`text-field-label`}>
                          <span  >
                            Date of Birth
                          </span>
                        </span>
                      )}
                      <DatePicker
                        key={dob}
                        inputRef={datePickerRef}
                        disabled={!isDOBEditable}
                        maxDate={moment()}
                        value={dob != null ? moment(dob) : null}

                        format='DD/MM/YYYY'
                        onChange={(value) => {
                          setTimeout(() => {
                            if (moment().diff(value, 'years') < 18) {
                              notify({
                                message: "We're sorry. Mitsu is designed only for people who are 18 years and older.",
                                severity: 'error',
                                dismissible: true,
                              });
                              setDob(value);
                              forceRefresh(Math.random())
                              mixpanel.people.set('eligibility_age', false)
                            } else {
                              setDob(value);
                              mixpanel.people.set('eligibility_age', true)
                            }
                          }, 1000)
                        }}
                        onAccept={(value) => {
                          if (moment().diff(value, 'years') < 18) {
                            notify({
                              message: "We're sorry. Mitsu is designed only for people who are 18 years and older.",
                              severity: 'error',
                              dismissible: true,
                            });
                            setDob(value);
                            mixpanel.people.set('eligibility_age', false)
                            forceRefresh(Math.random())
                          } else {
                            setDob(value);
                            mixpanel.people.set('eligibility_age', true)
                          }
                        }}

                        slotProps={{
                          textField: {
                            InputProps: {
                              style: {
                                borderRadius: '8px',
                                backgroundColor: '#fcfcfc',
                                boxShadow: '0px 1px 2px rgba(16, 24, 40, 0.05)',
                                // border: '1px solid #d0d5dd'
                              }
                            },
                            inputProps: {
                              style: {
                                padding: '11px',
                                color: '#2a2522',
                                WebkitTextFillColor: '#2a2522'
                              }
                            }
                          }
                        }}
                      />
                    </label>
                  </LocalizationProvider>

                  {/* <Select
                    name="dob"
                    isDisabled={!isDOBEditable}
                    label="Date of Birth"
                    value={dobValue != null ? { label: dobLabel, value: dobValue } : null}
                    placeholder="Select"
                    required
                    onMenuOpen={handleOpen}
                  /> */}
                  <Select
                    name="gender"
                    value={gender}
                    isDisabled={!isGenderEditable}
                    onChange={(val) => setGender(val)}
                    label="Gender Identity"
                    options={genderOptions}
                    placeholder="Select"
                    useMitsuDialog
                    modalDialogTitle="Select Gender Identity"
                    required
                  />

                </div>
                <div style={{ height: '32px' }} />
                {!isOccupationHidden && <Select
                  name="occupation"
                  value={occupation}
                  isDisabled={!isOcuupationEditable}
                  onChange={(val) => setOccupation(val)}
                  options={occupationOptions}
                  label="Occupation"
                  placeholder="-- Choose Option --"
                  useMitsuDialog
                  modalDialogTitle="Select Occupation"
                  required
                />}
              </div>
            </div>
          </form>

          <div className={style.spacer} />
          <div style={{ height: '40px' }} />
        </div>
      </div>
      <div className={style.buttonRow}>
        <Button loading={isLoading} onClick={onSubmit} className={style.singleMainButton} disabled={!isFormValid}>
          Next
        </Button>
      </div>
    </div>
  );
};

export default CheckInBasicDetailsPage;
