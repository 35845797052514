import httpClient from './index.service';
import { getCurrentUserId, getUserDetails } from './selfcheckin.service';

export const getAvailableSlots = async () => {
  const response = await httpClient.get(`/appointments/timeslots`);
  try {
    return response.data;
  } catch (error) {
    console.error(error);
  }
};

export const scheduleTimeSlot = async (id: string) => {
  const response = await httpClient.post(`/appointments/schedule/timeslot`, {
    id: id,
  });
  try {
    return response;
  } catch (error) {
    console.error(error);
  }
};

export const getPreviousTimeSlot = async () => {
  const response = await httpClient.get(`/appointments/previous/timeslot`);
  try {
    return response.data;
  } catch (error) {
    console.error(error);
  }
};

export const cancelCall = async (reason: string) => {
  const response = await httpClient.patch(`/appointments/cancel-call`, {
    cancellationReason: reason,
  });
  try {
    return response;
  } catch (error) {
    console.error(error);
  }
};

export const getUserEmailId = async () => {
  const id = localStorage.getItem('email');
  if (!!!id) {
    const details = await getUserDetails();
    if (details.email) {
      localStorage.setItem('email', details.email);
      return details.email;
    }
  }
  return id;
};

export const getUserPhone = async () => {
  const id = localStorage.getItem('mobile');
  if (!!!id) {
    const details = await getUserDetails();
    if (details.mobile) {
      localStorage.setItem('mobile', details.mobile);
      return details.mobile;
    }
  }
  return id;
};

export const markCallScheduleStepCompleted = async () => {
  const id = await getCurrentUserId();
  const result = await httpClient.patch(`/users/step?id=${id}`, {
    stepsCompleted: 3,
  });
  try {
    return result;
  } catch (error) {
    console.error(error);
  }
};

export const getTherapistInfo = async () => {
  const result = await httpClient.get(`/appointments/therapist-info`);
  try {
    return result.data;
  } catch (error) {
    console.error(error);
  }
};
