import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App.tsx';
import './styles/base.scss';
import * as Sentry from "@sentry/react";
import { createRoutesFromChildren, matchRoutes, useLocation, useNavigationType } from 'react-router-dom';

if (
  `${import.meta.env.REACT_APP_BASE_URL}`.includes('prod-backend')) {


  Sentry.init({
    dsn: "https://c64c07d6ac5295bedbf6fdb7f24972da@o4506229011447808.ingest.sentry.io/4506347023826944",
    integrations: [
      new Sentry.BrowserTracing({
        // See docs for support of different versions of variation of react router
        // https://docs.sentry.io/platforms/javascript/guides/react/configuration/integrations/react-router/
        routingInstrumentation: Sentry.reactRouterV6Instrumentation(
          React.useEffect,
          useLocation,
          useNavigationType,
          createRoutesFromChildren,
          matchRoutes
        ),
      }),
      new Sentry.Replay()
    ],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    tracesSampleRate: 1.0,


    // Capture Replay for 10% of all sessions,
    // plus for 100% of sessions with an error
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
  });
}
if (!localStorage.getItem('initial_landing_url')) {
  localStorage.setItem('initial_landing_url', window.location.href)
}


ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
);
